import { Link } from "gatsby";
import React from "react";

import { TransitionLink } from "gatsby-plugin-transitions";

import iztokHeadshot from "../images/iztok.jpg";

function Header(props) {
  const path = props.location.pathname;
  var headerSize = "small";
  if (path == "/") {
    headerSize = "large";
  }
  return (
    <header className={"page-header page-header-size-" + headerSize}>
      <div className="flex flex-wrap items-center justify-between max-w-2xl px-4 mx-auto md:py-32">
        <figure className="page-header__headshot">
          <img
            src={iztokHeadshot}
            alt="Portrait of Iztok, quite handsome in his fancy shirt"
          />
        </figure>

        <h1 className="headline font-bold font-serif break-normal text-center text-gray-900 pt-6 pb-2 text-3xl md:text-4xl">
          <span className="headline__name">Iztok</span>
          <span className="headline__description">
            &nbsp;is a designer, engineer, marketer and most of all, a maker.
            <a className="text-teal-500" href="#footnote">
              *
            </a>
          </span>
        </h1>
        <nav className="flex content-center">
          <Link
            className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline font-semibold"
            to="/"
          >
            About me
          </Link>
          <Link
            className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline font-semibold"
            to="/notes"
          >
            Monthly notes
          </Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;

import React from "react";
import { TransitionProvider, TransitionViews } from "gatsby-plugin-transitions";

import Header from "../components/header";
import Footer from "../components/footer";

const Layout = ({ location, children }) => {
  return (
    <TransitionProvider
      location={location}
      className="flex flex-col font-sans min-h-screen text-gray-900 bg-gray-100"
    >
      <Header location={location} />
      <TransitionViews>{children}</TransitionViews>
      <Footer />
    </TransitionProvider>
  );
};

export default Layout;
